<template>
  <el-row>
    <el-col :span="6"
      ><div class="partBox">
        <div class="title">艺术教育核心素养测评现场管理系统</div>
      </div></el-col
    >
    <el-col :span="12">
      <div class="centerBox">
        <div class="examInfo">
          <div class="infoBox">
            <div class="infoLabel">测评项目：</div>
            <div class="infoCont">{{ testInfo.testName }}</div>
          </div>
          <div class="infoBox">
            <div class="infoLabel">考点：</div>
            <div class="infoCont">{{ testInfo.sitesName }}</div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="6">
      <div class="rightPart">
        <div class="userAndLogin">
          <div class="userBox">
            <div class="userImg">
              <i class="el-icon-user-solid" style="color: #2797ff"></i>
            </div>
            {{userInfo.userName}}
          </div>
          <div class="loginBox" @click="signOut">
            <v-icon name="sign-out-alt" /><span>退出登录</span>
          </div>
        </div>
        <div class="examStatus">
          <div class="countDown">
           测评时间：{{$utils.formatTime(testInfo.testStartTime,'YYYY.MM.DD hh:mm')}} - {{$utils.formatTime(testInfo.testEndTime,'YYYY.MM.DD hh:mm')}}
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex';
export default {
  name: 'pageHeader',
  props: {

  },
  computed:{
    ...mapState(['testInfo','userInfo']),
    
  }, 
  methods: {
    signOut() {
      this.$signOut.signOut();
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.partBox {
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 28px;
    white-space: nowrap;
  }
}
.centerBox {
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .examInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    .infoBox {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      margin-left: 15px;
      margin-right: 15px;
      .infoCont {
        border: 1px solid #8bc3f6;
        line-height: 24px;
        width: 180px;
        padding-left: 5px;
        background-color: #1a91ff;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
.rightPart {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  .userAndLogin {
    display: flex;
    justify-content: flex-end;
    .userBox {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      .userImg {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #95ccff;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
    .loginBox {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .fa-icon {
        margin-right: 3px;
      }
    }
  }
  .examStatus {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    font-size: 13px;
    color: #ffffff;
    .ing {
      line-height: 24px;
      padding: 0 8px;
      border-radius: 2px;
      background-color: #3da2ff;
    }
    .countDown {
      line-height: 24px;
      padding: 0 8px;
      border-radius: 2px;
      background-color: #3da2ff;
      margin-left: 3px;
    }
  }
}
</style>
