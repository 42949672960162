<template>
  <el-container>
    <el-header><pageHeader></pageHeader></el-header>
    <el-container>
      <el-aside width="100px"><pageAside cindex="0"></pageAside></el-aside>
      <el-main class="pageMain">
        <div class="container">
          <div class="czPart">
            <p>当前未分组人数：{{ noGroupCount }}   （小组选题后组员无法更改）</p>
            <el-button
              type="success"
              size="mini"
              @click.native.prevent="gotoAddGroup"
              >新建分组</el-button
            >
          </div>
          <div class="fzList" v-for="(item, index) in list" :key="index">
            <div class="fzItem">
              <div class="fzhead">第{{ item.groupNo }}组</div>
              <div class="fzCont">
                <div class="fzStuList">
                  <div
                    class="fzStuItem"
                    v-for="(item2, index2) in item.examineesList"
                    :key="index2"
                    @click="changeFz(item.groupNo, index, item2.examNo, index2)"
                  >
                    <div class="picBox">
                      <el-image
                        v-if="item2.regPhoto"
                        :src="item2.regPhoto"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                       <el-image
                        v-else-if="item2.stuSex=='male'"
                        :src="require('@/assets/images/male.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                       <el-image
                        v-else-if="item2.stuSex=='female'"
                        :src="require('@/assets/images/female.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <el-image
                        v-else
                        :src="require('@/assets/images/defaultUserPic.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <div class="shardowBox">
                        <div class="stuNo">{{ item2.controlNo }}</div>
                        <div class="changeFz">转移分组</div>
                      </div>
                    </div>
                    <div class="stuName">{{ item2.stuName }}</div>
                  </div>
                  <div
                    class="addBtn"
                    v-if="!item.hasChooseQuestion"
                    @click="gotoChangeGroup(item.groupNo)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-drawer
      :title="drawerTitle"
      :with-header="false"
      :visible.sync="drawerVis"
      :before-close="handleClose"
      size="20%"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <div class="drTitle">{{ drawerTitle }}</div>
        <div class="changeFzList">
           <el-card
            class="box-card"
            shadow="hover"
            @click.native.prevent="selectChangeGroupNo = 0"
            :class="{ selectedCard: selectChangeGroupNo === 0 }"
          >
            <div class="changeFzItem">
              <div class="cFzItemTop">新的分组</div>
            </div>
          </el-card>
          <el-card
            class="box-card"
            shadow="hover"
            v-for="(item, index) in list"
            :key="index"
            :hidden="item.groupNo == changeGroupNo"
            @click.native.prevent="selectChangeGroupNo = item.groupNo"
            :class="{ selectedCard: selectChangeGroupNo == item.groupNo }"
          >
            <div class="changeFzItem">
              <div class="cFzItemTop">第{{ item.groupNo }}组</div>
              <div class="cFzItemBottom">测评号：{{ item.noArr }}</div>
            </div>
          </el-card>
        </div>
        <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button
            type="primary"
            :disabled="selectChangeGroupNo === ''"
            @click="changeGroup"
            :loading="loading"
            >{{ loading ? "提交中 ..." : "确 定" }}</el-button
          >
        </div>
      </div>
    </el-drawer>
  </el-container>
</template>
<script>
import {
  mapState
} from 'vuex';
import pageHeader from '@/components/pageHeader.vue';
import pageAside from '@/components/pageAside.vue';
export default {
  components: {
    pageHeader,
    pageAside
  },
  computed: mapState(['testInfo', 'userInfo']),
  data() {
    return {
      list: '',
      noGroupCount: '',
      drawerVis: false,
      loading: false,
      timer: null,
      changeGroupNo: '',
      selectChangeGroupNo: '',
      changeExamNo: '',
      drawerTitle: ''
    }
  },
  created() {
    this.getGroupWithDetailList();
  },
  methods: {
    getGroupWithDetailList() {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId
      }
       const loading = this.$loading({
          lock: true,
           background: 'rgba(0, 0, 0, 0.3)',
           text: '加载中...',
        });
      that.$api.getGroupWithDetailList(param).then(res => {
        if (res.success) {
          let result = res.body.list;
          this.noGroupCount = res.body.noGroupCount;
          for (var i in result) {
            var noArr = '';
            for (var o in result[i].examineesList) {
              noArr += result[i].examineesList[o].controlNo + ',';
            }
            result[i].noArr = noArr.substring(0, noArr.length - 1);
          }
          that.list = result;
        } else {
          that.$message(res.msg)
        }
        loading.close();
      })
    },
    changeFz(groupNo, fzIndex, examNo, stuIndex) {
      this.changeExamNo = examNo;
      this.drawerTitle = '将 ' + this.list[fzIndex].examineesList[stuIndex].controlNo + '号 ' + this.list[fzIndex].examineesList[stuIndex].stuName + ' 转移到'
      this.changeGroupNo = groupNo;
      this.drawerVis = true;
    },
    changeGroup() {
      const that = this;
      let groupNo = that.selectChangeGroupNo;
      if (groupNo === '') {
        return;
      }
      let examineesArray = [];
      examineesArray.push(that.changeExamNo);
      console.log(examineesArray);
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        examineesArray: examineesArray,
        groupNo: groupNo===0?'':groupNo
      }
      that.loading = true;
      that.$api.changeGroup(param).then(res => {
        if (res.success) {
          that.$message('转移成功');
          that.getGroupWithDetailList();
          this.drawerVis = false;
          this.selectChangeGroupNo = '';
          this.changeExamNo = '';
        } else {
          that.$message(res.msg)
        }
        that.loading = false;
      })
    },
    handleClose() {
      if (this.loading) {
        return;
      }
      this.drawerVis = false;
      this.selectChangeGroupNo = '';
      this.changeExamNo = '';
    },
    cancelForm() {
      this.loading = false;
      this.drawerVis = false;
    },
    gotoAddGroup() {
      this.$router.push({
        path: '/addGroup'
      })
    },
    gotoChangeGroup(groupNo) {
      this.$router.push({
        path: '/addGroup',
        query: {
          groupNo: groupNo
        }
      })
    }
  }
};
</script>
<style >
#app {
  display: flex;
}
.el-header {
  background-color: #2797ff;
  height: 85px !important;
}

.el-aside {
  background-color: #2797ff;
}

.el-main {
  background-color: #0e8bff;
  color: #ffffff;
}

body > .el-container {
  margin-bottom: 40px;
}
.el-container {
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.fzList {
  margin-top: 20px;
  .fzItem {
    margin-bottom: 30px;
    .fzhead {
      height: 26px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      line-height: 26px;
      font-size: 12px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.12);
      padding-left: 10px;
    }
    .fzCont {
      display: flex;
      margin-top: 30px;
      .fzStuList {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .fzStuItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 20px;
          .picBox {
            width: 60px;
            height: 60px;
            border: 1px solid #dcdcdc;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .stuPic {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 1;
            }
            .shardowBox {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 2;
              background-color: rgba(0, 0, 0, 0.6);
              .stuNo {
                color: #ffffff;
                font-size: 22px;
                font-weight: 700;
                line-height: 24px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .changeFz {
                width: 100%;
                height: 50%;
                color: #ffffff;
                background-color: #14d19e;
                font-size: 12px;
                padding-top: 5px;
                text-align: center;
                transition: all linear 0.3s;
                display: none;
              }
            }
          }
          .stuName {
            font-size: 14px;
            margin-top: 10px;
            color: #ffffff;
          }
        }
        .addBtn {
          position: relative;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 0.5);
          background-color: rgba(255, 255, 255, 0.1);
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            width: 30px;
            height: 3px;
            border-radius: 2px;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: #ffffff;
          }
          &::after {
            content: "";
            position: absolute;
            height: 30px;
            width: 3px;
            border-radius: 2px;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: #ffffff;
          }
        }
      }
      .fzStuItem:hover .picBox {
        border: 1px solid #14d19e;
        box-shadow: 1px 1px 5px 1px rgba(20, 209, 158, 0.6);
      }
      .fzStuItem:hover .changeFz {
        display: block !important;
        transition: all linear 0.3s;
      }
      .fzStuItem:hover .stuNo {
        font-size: 16px !important;
        padding-top: 5px;
      }
      .fzStatus {
        margin-left: 30px;
        width: 70px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        color: #ffffff;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.12);
        margin-top: 20px;
        &.isOver {
          background: rgba(20, 209, 158, 0.6);
        }
      }
    }
  }
}
.drTitle {
  padding: 30px 20px;
  font-size: 18px;
}
.changeFzList {
  padding: 20px;
  flex: 1;
  overflow-y: scroll;
  /deep/ .el-card__body {
    padding: 0 !important;
  }
  .box-card {
    margin-bottom: 20px;
    cursor: pointer;
    &.selectedCard {
      box-shadow: 0 2px 12px 0 #419eff;
      border: 1px solid #419eff;
      .cFzItemTop {
        background-color: #0c7ae0;
        color: #ffffff;
      }
    }
  }
  .changeFzItem {
    position: relative;

    .cFzItemTop {
      height: 50px;
      line-height: 50px;
      background-color: #dcdcdc;
      padding-left: 10px;
      color: #333;
      font-size: 15px;
    }

    .cFzItemBottom {
      height: 25px;
      line-height: 25px;
      color: #999;
      font-size: 12px;
      padding-left: 10px;
    }
  }
}
/deep/ .el-drawer__body {
  display: flex;
  overflow: hidden;
}
.demo-drawer__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.demo-drawer__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  button {
    flex: 1;
  }
}
</style>